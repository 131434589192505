const {v4} = require("uuid");
let server = null
let isLoading = false
let loadingEle = null
let toastBody = null

const urlList = [
	'https://rev0.aizhiyuan.cc',
	'https://rev1.aizhiyuan.cc',
	'https://rev0.khljbzxpeytzgsqgtfki.cc',
	'https://rev1.khljbzxpeytzgsqgtfki.cc',
	'https://rev0.nebulabinaryproxy.win',
	'https://rev1.nebulabinaryproxy.win',
	'https://rev0.goalphacloud.com',
	'https://rev1.goalphacloud.com',
]

async function getServerUrl (index = 0) {

	if (server) {
		return
	}
	if (window.Android && typeof window.Android.onEvent === 'function') {
		window.Android.onEvent('showLoading', JSON.stringify({tag: 'getServerUrl'}))
	} else {
		showLoading()
	}
	try {
		const url = urlList[index]
		const result = await getRequestAsync('web/test', url)
		if (result?.ok === true) {
			const text = await result.text()

			server = url

		} else if (index < urlList.length) {
			const newIndex = index + 1
			await getServerUrl(newIndex)
		}
	} finally {
		if (window.Android && typeof window.Android.onEvent === 'function') {
			window.Android.onEvent('hideLoading', JSON.stringify({tag: 'getServerUrl'}))
		}
		else {
			hideLoading()
		}
	}

}


function verifyEmail(email) {
	const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (email.value === "") {
		return false;
	}
	return reg.test(email);
}

const momentTz = require('moment-timezone');
import FingerprintJS from '@fingerprintjs/fingerprintjs';

async function getFingerprint () {
	const fp = await FingerprintJS.load({screen_resolution: true});

	const result = await fp.get();

	return result.visitorId;
}

async function getRequestAsync(url, baseUrl) {
	const myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	if (sessionId) {
		myHeaders.append("sessionId", sessionId);
	}

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	// const self = this
	if (baseUrl) {
		const response = await fetch(`${baseUrl}/${url}`, requestOptions)
		return response
	} else {
		const response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	}

}

async function postRequestAsync(url, data) {
	var myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "49.51.186.159");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	if (sessionId) {
		myHeaders.append("sessionId", sessionId);
	}

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var raw = JSON.stringify(data);

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// const self = this

	const response = await fetch(`${server}/${url}`, requestOptions)
	return response.json()
}



function showLoading () {
	if (isLoading === false) {
		const loadingContainer = document.createElement("div")
		loadingContainer.classList.add('loader-container')
		let loadingBody = document.createElement("div")
		loadingBody.classList.add('loader');
		loadingContainer.append(loadingBody)
		// toastBody.innerText = text
		document.body.append(loadingContainer)
		isLoading = true
		loadingEle = loadingContainer
	}

}

function startDownload (url) {
	let a = document.createElement('a');
	a.setAttribute('download', '');// download属性
	a.setAttribute('href', url);// href链接
	a.click();// 自执行点击事件
	a = null;
}

function hideLoading () {
	if (loadingEle) {
		document.body.removeChild(loadingEle)
		isLoading = false
		loadingEle = null
	}
}

function showToast(text) {
	if (toastBody) {
		document.body.removeChild(toastBody)
		toastBody = null
	}
	toastBody = document.createElement("div");
	toastBody.classList.add('sl-toast');
	toastBody.innerText = text
	document.body.append(toastBody)
	const timeoutID = window.setTimeout(() => {
		if (toastBody) {
			document.body.removeChild(toastBody)
			toastBody = null
		}
		window.clearTimeout(timeoutID)
	}, 3000);
}

function getClientId() {
	let clientId = localStorage.getItem('clientId')
	if (clientId) return clientId
	clientId = v4()
	localStorage.setItem('clientId', clientId)
	return clientId
}

function getUrlType() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get('type')
}

function getUrlId() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get('id')
}

async function getWindowsDownloadLink (type='net6.0') {
	const url = window.location.href;
	const result = await postRequestAsync('download/getWindowsDownloadLink', {url, type})

	return result.msg.url
}

async function getAndroidDownloadLink(referCode) {
	const url = window.location.href;

	const result = await postRequestAsync('download/getAndroidDownloadLink', { referCode: referCode, url })

	return result.msg.url
}

async function getR2MacosDownloadLink () {
	const url = window.location.href;
	const result = await postRequestAsync('download/getR2MacosDownloadLink', {url})

	return result.msg.url
}

async function isSessionValid () {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) return {err: true}
	const result = await postRequestAsync('webLogin/verifySessionId', {sessionId: sessionId})
	if (!result) {
		localStorage.removeItem('sessionId')
		localStorage.removeItem('sessionExpireTimeStamp')
		return {err: true}
	}
	if (result.code === 200) {
		const expiredAtTimestamp = result.msg.expiredAtTimestamp
		localStorage.setItem('sessionExpireTimeStamp', expiredAtTimestamp)
		return {err: false, expiredAtTimestamp: expiredAtTimestamp, email: result.msg.email, planExpireTime: result.msg.planExpireTime}
	}

	localStorage.removeItem('sessionId')
	localStorage.removeItem('sessionExpireTimeStamp')
	return {err: true}
}

function formatTimestamp(timestamp) {

// 创建一个新的Date对象
	var date = new Date(timestamp * 1000);

// 使用Date对象的方法来获取日期和时间的各个部分
	var year = date.getFullYear(); // 获取年份
	var month = date.getMonth() + 1; // 获取月份，注意月份从0开始，所以需要加1
	var day = date.getDate(); // 获取日期
	var hours = date.getHours(); // 获取小时
	var minutes = date.getMinutes(); // 获取分钟
	var seconds = date.getSeconds(); // 获取秒数

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

async function getPlanInfo () {
	const result = await getRequestAsync('web/getPlanInfo')

	return result
}

async function getAlipayLink (planType) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/getAliPaymentLinkV2', {planType, sessionId})
}

async function getWechatPayLink (planType) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/getWechatPaymentLinkV2', {planType, sessionId})
}

async function getCreditCardPaymentLink (planType) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/getCreditCardPaymentLink', {planType, sessionId})
}

async function getUsdtTransactionId (planType) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/createTransaction', {planType, sessionId})
}
async function getTransactionById (transactionId) {
	return await postRequestAsync('web/getTransactionById', {transactionId})
}

async function verifyTransaction (transactionId) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/verifyTransaction', {transactionId, sessionId})
}

async function reportVisit (actionType = 'web_visit') {
	const url = window.location.href;
	// let fbp = createFbp()
	const fingerprint = await getFingerprint()
	return await postRequestAsync('web/reportVisit', {url, fingerprint, actionType})
}

function getSessionId () {
	return  localStorage.getItem('sessionId')
}

function reportGoogle(url = undefined) {

	if (typeof window.gtag === 'function') {
		var callback = function () {
			if (typeof (url) != 'undefined') {
				window.location = url;
			}
		}
		window.gtag('event', 'conversion', {
			'send_to': 'AW-16533576363/GYxRCLz3v6oZEKu16cs9',
			'event_callback': callback
		});
	}
}

function reportFb (actionType) {
	if (typeof window.fbq === 'function') {
		window.fbq('trackCustom', actionType);
	}
}

function detectOS() {
	const userAgent = navigator.userAgent;

	if (/Windows/i.test(userAgent)) {
		return 'Windows';
	} else if (/Android/i.test(userAgent)) {
		return 'Android';
	} else if (/iPhone|iPad|iPod/i.test(userAgent)) {
		return 'iOS';
	} else if (/Mac OS/i.test(userAgent)) {
		return 'Mac';
	} else {
		return 'unknown';
	}
}

function createFbp() {
	// 先检查是否已存在_fbp cookie
	let fbp = getFbpFromCookie();
	if (fbp) {
		return fbp;
	}

	// 不存在则创建新的fbp
	fbp = generateFbp();
	setFbpCookie(fbp);
	return fbp;
}

function getFbpFromCookie() {
	const cookies = document.cookie.split(';');
	for (let cookie of cookies) {
		cookie = cookie.trim();
		if (cookie.startsWith('_fbp=')) {
			return cookie.substring(5);
		}
	}
	return null;
}

function generateFbp() {
	// 固定前缀
	const prefix = 'fb.1.';

	// 时间戳(Unix时间秒)
	const timestamp = Math.floor(Date.now() / 1000);

	// 8位随机数
	const random8 = Math.floor(Math.random() * 100000000).toString().padStart(8, '0');

	// 4位随机数
	const random4 = Math.floor(Math.random() * 10000).toString().padStart(4, '0');

	return `${prefix}${timestamp}.${random8}.${random4}`;
}

function setFbpCookie(fbp) {
	// 设置3个月过期时间
	const expiryDate = new Date();
	expiryDate.setMonth(expiryDate.getMonth() + 3);

	// 设置cookie
	document.cookie = `_fbp=${fbp};` +
		`expires=${expiryDate.toUTCString()};` +
		`path=/;` +
		`domain=${window.location.hostname};` +
		`SameSite=Lax`;
}

export {
	getRequestAsync,
	postRequestAsync,
	verifyEmail,
	getAndroidDownloadLink,
	startDownload,
	showLoading,
	hideLoading,
	showToast,
	getClientId,
	getUrlType,
	getUrlId,
	getWindowsDownloadLink,
	isSessionValid,
	formatTimestamp,
	getPlanInfo,
	getAlipayLink,
	getWechatPayLink,
	getCreditCardPaymentLink,
	getUsdtTransactionId,
	getTransactionById,
	verifyTransaction,
	reportVisit,
	getSessionId,
	reportGoogle,
	reportFb,
	detectOS,
	getServerUrl,
	getR2MacosDownloadLink
};
